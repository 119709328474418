import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// utils
import {axiosAdmin} from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  Slotbookings: [],
  bookingsList:[],
    category: '',
    filters: {
      name: [],
     
    },
    selectedCatId: null,
    count: null,
    pages: null,
    skip:null

};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getBookingSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload.list;
      state.count = action.payload.count;
      state.pages = action.payload.page;
      state.skip = action.payload.skip;
    },

    deleteBookingSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
     
    },

    // GET PRODUCT
    getBookingSuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },
    getAllBookingSuccess(state,action){
      state.isLoading = false;
      state.bookingsList = action.payload;
    }
 ,
    filterBooking(state, action) {
    //  state.filters.name = action.payload.name;
    state.categories = action.payload;
    },
   
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    // CHECKOUT
     
    deleteBookingSuccess(state, action) {
  //     const { id } = action.payload;
  //  state.categories =  state.categories.filter((category) => category.id !== id);
  state.isLoading = false;
  state.categories = action.payload; 

      
    },
 
    
    selectBooking(state, action) {
      const catId = action.payload;
      state.isOpenModal = true;
      state.selectedCatId = catId;
    },
    

 
  

  
    createSlotBookingSuccess(state, action) {
      const newCategory = action.payload;
      state.isLoading = false;
      console.log(newCategory)
      state.Slotbookings = [...state.Slotbookings, newCategory];
    },
 

   
    getBookingSuccessAll(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
       
    },
   

  
     
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
 getBookingSuccess,
 selectBooking,
 filterBooking,sortByProducts
} = slice.actions;

// ----------------------------------------------------------------------
 


export function createSlotBooking(newSlotslot,callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
        console.log(newSlotslot)
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.post('/slot-booking', newSlotslot);
        console.log(response.data)
        callBack(false);
      dispatch(slice.actions.createSlotBookingSuccess(response.data));
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


 

export function getSlotBooking(params) {
  return async () => {
    console.log(params)
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.get(`slot-booking/${params}`);
        console.log(response)
      dispatch(slice.actions.getAllBookingSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateSlotBookingStatus(id, status) {
  console.log(id)
  console.log(status)
const s={
  status:status
}
console.log(s)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.patch(`/clinic-booking/${id}/status`, s);
        console.log(response)
        // dispatch(slice.actions.getCategorySuccess(response.data));
      }
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateBooking(id, updateCategory) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.put(`/categories/${id}`,updateCategory);
      dispatch(slice.actions.getCategorySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

 
export function deleteBooking(categoryID) {

 // alert(categoryID)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
     if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

    const response =   await axiosAdmin.delete(`/categories/${categoryID}`);
      dispatch(slice.actions.deleteCategoriesSuccess(response.data));
     }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



 
export function deleteManyBooking(storeId) {

  // alert(offerId)
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
      const response =   await axiosAdmin.post('/categories/remove-many',storeId);
    
        dispatch(slice.actions.deleteCategorySuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }