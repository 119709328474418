import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';

// utils
import { axiosAdmin } from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    offers: [],
    offer: {},
    dept: [],
    deptSingle:{}
};

const slice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PRODUCTS
        getOffersSuccess(state, action) {
            state.isLoading = false;
            state.offers = action.payload;
        },

        getDeptSuccess(state, action) {
            state.isLoading = false;
            state.dept = action.payload; // Store the department data here
        },

        getSingleDeptSuccess(state, action) {
            state.isLoading = false;
            state.deptSingle = action.payload; // Store the department data here
        },
        deleteOffersSuccess(state, action) {
            // state.isLoading = false;
            // state.offers = action.payload;
            const { id } = action.payload;
            state.offers = state.offers.filter((offer) => offer.id !== id);
        },

        // GET PRODUCT
        getOfferSuccess(state, action) {
            state.isLoading = false;
            state.offer = action.payload;
        },
        createOfferSuccess(state, action) {
            const newOffer = action.payload;
            state.isLoading = false;
            state.offers = [...state.offers, newOffer];
        },

        // CHECKOUT

        deleteOfferSuccess(state, action) {
            //     const { id } = action.payload;

            //  state.offers =  state.offers.filter((offer) => offer.id !== id);
            state.isLoading = false;
            state.offers = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { getOfferSuccess, getOffersSuccess, getDeptSuccess } = slice.actions;


export function createDepartment(newStore, callBack) {
    return async (dispatch) => {
        console.log(newStore);

        try {
            const response = await axiosAdmin.post('/department', newStore);
            console.log(response)
            callBack(false);
        } catch (error) {
            callBack(error);

            console.error('Error creating offer:', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateDepartment(id,newStore, callBack) {
    console.log(id)
    return async (dispatch) => {
        console.log(newStore);

        try {
            const response = await axiosAdmin.patch(`/department/${id}`, newStore);
            console.log(response)
            callBack(false);
        } catch (error) {
            callBack(error);

            console.error('Error creating dept:', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDepartmentByStoreId(storeId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const accessToken = window.localStorage.getItem('accessToken');

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);

                const response = await axiosAdmin.get(`/department/store/${storeId}`, {
                    params: { storeId },
                });
                console.log(response);
                dispatch(slice.actions.getDeptSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDepartment(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosAdmin.get(`/department/${id}`);
            console.log(response)
            dispatch(slice.actions.getSingleDeptSuccess(response.data));
            //  alert(response.data)
            console.log('getofffer', response.data);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateOffer(id, newStore, callBack) {
    console.log(id)
    return async (dispatch) => {
        console.log(newStore);
        // dispatch(slice.actions.startLoading());

        // Create a FormData object
        const formData = new FormData();

        // Convert dates to ISO 8601 strings and ensure the boolean field is a boolean
        formData.append('startsAt', new Date(newStore.startsAt).toISOString());
        formData.append('endsAt', new Date(newStore.endsAt).toISOString());
        formData.append('redemptionStartsAt', new Date(newStore.redemptionStartsAt).toISOString());
        formData.append('redemptionEndsAt', new Date(newStore.redemptionEndsAt).toISOString());
        formData.append('isFeatured', newStore.isFeatured);

        // Append all other fields except the images
        for (const key in newStore) {
            if (!['startsAt', 'endsAt', 'redemptionStartsAt', 'isFeatured', 'redemptionEndsAt', 'images'].includes(key)) {
                formData.append(key, newStore[key]);
            }
        }

        // Append the image file(s)
        if (newStore.images) {
            formData.append('images', newStore.images);
        }

        try {
            // Send the form data using axios

            const response = await axiosAdmin.put(`/offers/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response)
            callBack(false);

            // Call the callback function if provided


            // dispatch(slice.actions.stopLoading());
        } catch (error) {
            callBack(error);

            console.error('Error creating offer:', error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
// ----------------------------------------------------------------------

export function deleteDepartment(offerId) {
    console.log(offerId);
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosAdmin.delete(`/department/${offerId}`);
console.log(response)
            // dispatch(slice.actions.deleteOfferSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteManyDepartment(offerId) {
    console.log('first');
    // alert(offerId)
    console.log(offerId);
    // {"departmentIds":[4,1]}
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosAdmin.delete('/department/delete/multiple', {
                data: offerId, 
                headers: {
                    'Content-Type': 'application/json', 
                }
            });
            console.log(response);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

